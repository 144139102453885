module.exports = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    25: '#F7F8FA',
    50: '#F2F4F7',
    100: '#DCE0E6',
    200: '#C0C5CC',
    300: '#A2A8B0',
    400: '#878E96',
    500: '#696F78',
    600: '#4F5359',
    700: '#353940',
    800: '#202429',
    900: '#12151A',
  },
  yellow: {
    100: '#FDFDEA',
    200: '#FDF5B2',
    300: '#FCE969',
    400: '#FACA15',
    500: '#E3A007',
    600: '#C27702',
    700: '#8D4B10',
    800: '#733B13',
    900: '#633212',
  },
  green: {
    100: '#F2FAF7',
    200: '#DEF7EC',
    300: '#BDF0DA',
    400: '#83E2BC',
    500: '#30C38D',
    600: '#0FA06E',
    700: '#097A55',
    800: '#045449',
    900: '#01473C',
  },
  cyan: {
    100: '#F4FDFF',
    200: '#E7F9FD',
    300: '#CBF3FD',
    400: '#A7E7F8',
    500: '#6BDBFA',
    600: '#10BCED',
    700: '#067C9F',
    800: '#04556E',
    900: '#013140',
  },
  blue: {
    25: '#F5FAFF',
    50: '#E6F2FF',
    100: '#CCE4FF',
    200: '#B3D7FF',
    300: '#8CC0FF',
    400: '#69ACFF',
    500: '#3B8CFF',
    600: '#136DEC',
    700: '#0C47A6',
    800: '#002E73',
    900: '#001D45',
  },
  purple: {
    100: '#F9F7FF',
    200: '#F4EFFF',
    300: '#E6DCFA',
    400: '#D5C4F8',
    500: '#AE96E0',
    600: '#805AD5',
    700: '#553A91',
    800: '#3B256A',
    900: '#23163E',
  },
  red: {
    100: '#FFF5F6',
    200: '#FFEFF0',
    300: '#FCDADD',
    400: '#F7BEC3',
    500: '#EB868F',
    600: '#D94D59',
    700: '#902B34',
    800: '#622127',
    900: '#390F13',
  },
};
