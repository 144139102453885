/* A wrapper components that applies Tailwind's pre-flight base styles:
 * https://unpkg.com/tailwindcss@2.0.1/dist/base.css
 *
 * Emotion's global component: https://emotion.sh/docs/globals
 *
 * This component was created following Twin Macro configuration example:
 * https://github.com/ben-rogerson/twin.examples/tree/master/gatsby-emotion
 */

import React from 'react';
import { GlobalStyles, css } from 'twin.macro'; // Tailwind's global styles
import { Global } from '@emotion/react';
import PropTypes from 'prop-types';
import tailwindColors from '../../tailwind-colors';

// Add global CSS styles to this variable below
const globalCSS = css`
  html {
    background-color: ${tailwindColors.gray[50]};
    font-size: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    height: 100%;
  }

  /* Remove scroll bars */
  html {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  html::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  // svg logos need width and height declarations in order to appear on iPhones
  svg {
    height: 100%;
    width: 100%;
  }
`;

export default function PageWrapper(props) {
  const { children } = props;
  return (
    <div>
      <GlobalStyles />
      <Global styles={globalCSS} />
      {children}
    </div>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
